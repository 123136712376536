@import '../pdGlobal/PdGlobal';

.our-services {
    @include media-breakpoint-up(md) {
        padding-right: rem(80);
        padding-left: rem(80);
    }

    @include media-breakpoint-up(lg) {
        padding: rem(10) rem(180);
    }

    .photo-tile-container {
        a {
            &:focus {
                outline: none;
            }
        }
    }

    .region::before {
        width: 0;
    }

    .tile-image .img-fluid {
        @include media-breakpoint-only(md) {
            max-width: 70%;
        }
    }

    .col-12:nth-child(1) {
        padding: rem(0) rem(16);

        .experience-commerce_layouts-mobileGrid3r1c:nth-child(2) {
            .mobile-3r-1c {
                padding: rem(0) rem(55);

                @include media-breakpoint-up(md) {
                    padding: 0;
                }

                .mx-n2 {
                    border: 2px solid $light-red;
                    margin: rem(60);

                    @include media-breakpoint-up(md) {
                        border: none;
                    }

                    .region:nth-child(1) {
                        margin-top: rem(35);

                        @include media-breakpoint-up(md) {
                            margin-top: 0;
                        }
                    }

                    .region:nth-child(1),
                    .region:nth-child(2) {
                        .photo-tile-figure {
                            margin: auto;
                        }

                        border: none;

                        @include media-breakpoint-up(md) {
                            border-right-style: solid;
                            border-color: $light-red;
                            border-width: rem(2);
                        }
                    }

                    .region:nth-child(3) {
                        margin-bottom: rem(24);

                        @include media-breakpoint-up(md) {
                            margin-bottom: auto;
                        }
                    }
                }
            }
        }

        .experience-commerce_layouts-mobileGrid3r1c:nth-child(3) {
            .mobile-3r-1c {
                border: none;
                padding: rem(0) rem(55);

                @include media-breakpoint-up(md) {
                    border-top-style: solid;
                    border-color: $light-red;
                    border-width: rem(2);
                    padding: 0;
                }

                .mx-n2 {
                    margin-top: rem(60);

                    .region:nth-child(1),
                    .region:nth-child(2) {

                        .photo-tile-figure {
                            margin: auto;
                        }

                        .col-12 {
                            padding: 0;
                        }

                        border: none;

                        @include media-breakpoint-up(md) {
                            border-right-style: solid;
                            border-color: $light-red;
                            border-width: rem(2);
                        }
                    }
                }

                .region:nth-child(3) {
                    .photo-tile-column {
                        padding: 0;
                    }
                }
            }
        }

        .experience-commerce_layouts-mobileGrid2r1c {
            margin-bottom: rem(20);

            @include media-breakpoint-up(md) {
                margin-bottom: rem(40);

                .col-12 {
                    padding: rem(10);
                }
            }
        }

        .experience-commerce_layouts-mobileGrid1r1c:nth-child(5) {
            @include media-breakpoint-down(md) {
                .region {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .photo-tile-image {
                        width: rem(135);
                    }
                }
            }
        }

        .experience-commerce_layouts-mobileGrid1r1c:nth-child(6) {
            margin-bottom: rem(70);

            .region {
                display: flex;
                align-items: center;
                justify-content: center;

                .experience-commerce_assets-photoTile {
                    width: 15%;
                }

                .experience-commerce_assets-photoTile:nth-child(1) {
                    margin-right: rem(20);
                }

                .experience-commerce_assets-photoTile:nth-child(2) {
                    margin: rem(0) rem(2);
                }

                .experience-commerce_assets-photoTile:nth-child(3) {
                    margin-left: rem(20);
                }
            }

            @include media-breakpoint-up(md) {
                .col-12 {
                    padding: 0;
                }

                .region {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .experience-commerce_assets-photoTile {
                        width: 6%;
                    }

                    .experience-commerce_assets-photoTile:nth-child(1) {
                        margin-right: rem(85);
                    }

                    .experience-commerce_assets-photoTile:nth-child(2) {
                        margin: rem(0) rem(50);
                    }

                    .experience-commerce_assets-photoTile:nth-child(3) {
                        margin-left: rem(85);
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                margin: rem(30) rem(0) rem(170) rem(0);
            }
        }
    }
}

//Social Impact
.social-impact {
    .rich-text-background {
        .para-social {
            line-height: rem(24);
        }
    }

    .experience-commerce_layouts-mobileGrid3r1c {
        .mobile-3r-1c {
            padding: 0 rem(8);
        }

        .tile-image-cta {
            padding-bottom: rem(84);

            .heading {
                height: rem(270);
                align-items: center;
            }

            .image-CTA-placement {
                position: absolute;
                bottom: 0;
            }

            .tile-image {
                padding: rem(20) rem(40) 0;

                @include media-breakpoint-up(md) {
                    padding: 0;
                }
            }
        }

        .region {
            padding: 0;

            @include media-breakpoint-up(md) {
                &:nth-child(1) {
                    padding-right: rem(12);
                }
    
                &:nth-child(2) {
                    padding: 0 rem(8) 0 rem(4);
                }
    
                &:nth-child(3) {
                    padding-left: rem(8);
                }
            }
        }
    }

    .experience-commerce_layouts-mobileGrid2r1c {
        .mobile-2r-1c {
            padding: 0 rem(8);
        }

        .image-text {
            .tile-image {
                height: auto;
                align-items: center;
                padding-bottom: rem(24);

                @include media-breakpoint-up (md) {
                    height: rem(145);
                }
            }
        }
    }

    .experience-commerce_assets-imageWithTextAndTwoCTA:nth-child(1) {
        .image-with-text-container {
            .image-component {
                margin-bottom: 0;

                .text-container-placement {
                    padding: 0;
                    margin: 0;

                    @include media-breakpoint-up(md) {
                        padding: rem(48);
                        margin: 0;
                    }

                    .heading-text {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: 0;

                        @include media-breakpoint-up(md) {
                            display: initial;
                            position: relative;
                            padding: 0;
                        }

                        .heading {
                            text-align: left;
                            margin-top: rem(24);
                            margin-left: rem(24);

                            @include media-breakpoint-up(md) {
                                margin-top: 0;
                                margin-left: 0;
                                margin-bottom: rem(32);
                            }
                        }

                        .description {
                            text-align: left;
                            margin-top: rem(64);
                            max-width: 65%;
                            margin-left: rem(24);

                            @include media-breakpoint-up(md) {
                                margin-top: 0;
                                margin-left: 0;
                                max-width: 100%;
                            }
                            @include media-breakpoint-up(lg) {
                                max-width: 80%;
                            }
                        }

                        .sub-heading-text {
                            margin-bottom: rem(24);
                        }
                    }
                }

                .cta-text {
                    margin: 0;
                    padding: 0;
                }

            }
        }
    }

    .experience-commerce_assets-wideImageWithText {
        .rich-text-content {
            margin-top: 0;
        }
    }

    .experience-commerce_assets-imageWithTextAndTwoCTA:nth-child(6) {
        .image-with-text-container {
            .image-component {
                margin-bottom: 0;

                .text-container-placement {
                    padding: rem(24) 0 0 0;
                    margin: 0;

                    @include media-breakpoint-up(md) {
                        padding: rem(48);
                        margin: 0;
                        max-width: 70%;
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 44%;
                    }

                    .heading-text {
                        background-color: $content-background-color;
                        padding: rem(24);

                        @include media-breakpoint-up(md) {
                            background-color: transparent;
                            padding: 0;
                        }

                        .heading {
                            text-align: center;
                            padding: 0;
                            background-color: transparent;
                            margin-bottom: rem(16);

                            @include media-breakpoint-up(md) {
                                text-align: left;
                                padding: 0;
                                max-width: 80%;
                                margin-bottom: rem(32);
                            }
                        }

                        .description {
                            text-align: center;
                            margin-top: rem(16);

                            @include media-breakpoint-up(md) {
                                text-align: left;
                                max-width: 63%;
                                margin-top: 0;
                            }
                        }

                        .sub-heading-text {
                            margin-bottom: rem(24);
                        }
                    }
                }

                .cta-text {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    .experience-commerce_assets-imageWithCTAFlexibility {
        padding-bottom: 0;

        .image-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .banner-image-text {
                padding: 0 rem(16);

                @include media-breakpoint-up(lg) {
                    padding: 0 rem(48);
                }
            }

            .image-CTA-placement {
                .cta-button {
                    .cta-button {
                        padding: 0 rem(60);
                        text-decoration: none;
                        &:hover {
                            opacity: 0.70;
                        }
                    }
                }
            }
        }
    }

    .experience-commerce_layouts-mobileGrid2r1c {
        padding: rem(32) 0;
        border-bottom: rem(1) solid $primary-black;

        @include media-breakpoint-up(md) {
            padding: rem(48) 0;
        }

        .region:nth-child(1) {
            .image-text {
                border-right: none;
                padding-bottom: rem(32);
                border-bottom: rem(1) solid $primary-black;

                @include media-breakpoint-up(md) {
                    border-right: rem(1) solid $primary-black;
                    border-bottom: none;
                    padding-bottom: 0;
                }

            }
        }

        .region:nth-child(2) {
            .image-text {
                padding-top: rem(32);

                @include media-breakpoint-up(md) {
                    padding-top: 0;
                }

                .banner-image-text {
                    padding-top: 0;
                }
            }
        }

        .image-text {
            padding: 0;

            @include media-breakpoint-up(md) {
                padding: 0 rem(80);
            }
        }
    }

    .experience-commerce_assets-imageWithCTAFlexibility:nth-child(9) {
        padding-top: rem(32);
        padding-bottom: rem(32);

        @include media-breakpoint-up(md) {
            padding-top: rem(48);
            padding-bottom: rem(48);
        }

        .image-text {
            .banner-image-text {
                padding: 0 rem(16);

                @include media-breakpoint-up(md) {
                    padding: 0;
                    max-width: 46%;
                }

                @include media-breakpoint-up(lg) {
                    padding: 0;
                    max-width: 28%;
                }
            }

            .image-CTA-placement {
                width: auto;
            }
        }
    }

    .mobile-2r-1c {
        .image-text {
            .banner-image-text {
                @include media-breakpoint-up(md) {
                    padding: 0;
                }
            }

            .image-CTA-placement {
                padding-left: rem(80);
                padding-right: rem(80);
                width: 100%;
    
                @include media-breakpoint-up(md) {
                    padding-left: rem(20);
                    padding-right: rem(20);
                }
    
                @include media-breakpoint-up(lg) {
                    padding-left: rem(80);
                    padding-right: rem(80);

                    .cta-button {
                        width: rem(225);
                        margin: 0 auto;
                    }
                }
    
                .cta-button {
                    .cta-button {
                        padding: 0;
                    }
                }
            }
        }
    }
}

//Brand Page
.brand-page {
    .experience-commerce_assets-imageWithCTAFlexibility {
        .image-text {
            .image-CTA-placement {
                padding-left: rem(80);
                padding-right: rem(80);

                @include media-breakpoint-up(md) {
                    padding-left: rem(20);
                    padding-right: rem(20);
                }

                @include media-breakpoint-up(lg) {
                    padding-left: rem(80);
                    padding-right: rem(80);
                }

                .cta-button {
                    .cta-button {
                        text-decoration: none;
                        &:hover {
                            opacity: 0.70;
                        }
                    }
                }
            }
        }
    }

    .experience-commerce_layouts-mobileGrid3r1c {
        .mobile-3r-1c {
            padding: 0 rem(24);
            max-width: 100%;
            @include media-breakpoint-up(md){
                max-width: rem(1342);
                margin: auto;
            }
        }

        .tile-image-cta {
            padding-bottom: rem(84);
        }

        .image-text {
            .image-CTA-placement {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
    }

    .experience-commerce_layouts-mobileGrid2r1c,
    .experience-commerce_layouts-mobileGrid3r1c:nth-child(2) {
        .image-text {
            .tile-image {
                height: rem(145);
                align-items: center;
            }
        }
    }

    .experience-commerce_layouts-mobileGrid2r1c {
        .mobile-2r-1c {
            padding: 0 rem(24);
            max-width: 100%;
            @include media-breakpoint-up(md){
                max-width: rem(1342);
                margin: auto;
            }
        }
        .region {
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
                margin-bottom: rem(40);
            }
        }

        .mobile-2r-1c {
            padding: 0 rem(16);
        }

        padding-top: 0;
        border-bottom: none;

        @include media-breakpoint-up(md) {
            padding-top: rem(24);
            border-bottom: rem(1) solid $primary-black;
        }

        .tile-image-cta {
            margin-bottom: 0;
            padding-bottom: rem(40);

            @include media-breakpoint-up(md) {
                padding-bottom: rem(84);
                margin-bottom: 0;
            }
        }

        .region:nth-child(1) {
            .tile-image-cta {
                border-right: none;
                border-bottom: rem(1) solid $primary-black;

                @include media-breakpoint-up(md) {
                    border-right: rem(1) solid $primary-black;
                    border-bottom: none;
                }
            }
        }

        .region:nth-child(2) {
            .tile-image-cta {
                border-bottom: rem(1) solid $primary-black;

                @include media-breakpoint-up(md) {
                    border-bottom: none;
                }
            }
        }

        .image-text {
            padding: 0;

            @include media-breakpoint-up(md) {
                padding: 0 rem(80);
            }

            .image-CTA-placement {
                padding-bottom: rem(4);
                @include media-breakpoint-up(md) {
                    position: absolute;
                    bottom: 0;
                    width: 50%;
                }
                @include media-breakpoint-up (lg) {
                    padding-left: rem(140);
                    padding-right: 0;
                }
            }
        }
    }

    .experience-commerce_layouts-mobileGrid2r1c:nth-last-child(1) {
        border-bottom: none;
    }
}

// careers
.careers {
    .main-block {
        &.noBleed {
            padding-left: rem(12);
            padding-right: rem(12);

            @include media-breakpoint-up(md) {
                padding-left: rem(20);
                padding-right: rem(20);
            }

            @include media-breakpoint-up(lg) {
                padding-left: rem(80);
                padding-right: rem(80);
            }
        }
    }

    .body-text {
        &.rich-text-background {
            margin-top: rem(18);

            @include media-breakpoint-up(md) {
                margin-top: rem(30);
            }

            p {
                margin-top: rem(16);
                line-height: rem(24);
            }
        }
    }

    .wide-image-container {
        .noBleed {
            padding: 0;

            @include media-breakpoint-up(md) {
                padding: 0 rem(20);
            }

            @include media-breakpoint-up(lg) {
                padding: 0 rem(80);
            }

            .image-component {
                padding: 0;
            }
        }
    }

    .heading {
        text-transform: uppercase;
        text-align: center;
    }

    .noBleed {
        padding: rem(24) rem(12);

        @include media-breakpoint-up(md) {
            padding: rem(80);
        }
    }

    .paragraph-1 {
        padding: 0 rem(12);
        margin-bottom: rem(36);
        letter-spacing: 0.11em;
        color: $grey;

        @include media-breakpoint-up(md) {
            padding: 0 rem(30);
        }
    }

    .rich-text-content {
        margin-top: 0;
    }

    .color-block {
        height: auto;
    }

    .main-content-container {
        width: 100%;
    }

    .experience-commerce_assets-colorBlockwithImageAndCTA:nth-child(2) {
        .color-block {
            padding-left: rem(16);
            padding-right: rem(16);
        }

        .text-cta-container {
            padding: 0;
            width: 100%;

            @include media-breakpoint-up(md) {
                padding-left: rem(80);
                padding-right: 0;
            }

            @include media-breakpoint-up(lg) {
                max-width: 85%;
            }

            .text-block {
                text-align: left;

                @include media-breakpoint-up(md) {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 0;
                }
            }

            .pd-body-text {
                max-width: rem(300);
                padding-top: rem(24);
                padding-bottom: rem(24);

                @include media-breakpoint-up(md) {
                    padding-top: 0;
                    max-width: rem(390);
                    padding-bottom: rem(28);
                }
            }

            .pd-optional-body-text {
                padding-top: rem(24);
                border-top: rem(2) solid $orange-border;
                text-transform: none;

                @include media-breakpoint-up(md) {
                    padding-top: rem(28);
                }
            }

            .cta-block {
                width: rem(220);
                margin-top: 0;
                padding-top: rem(24);

                @include media-breakpoint-up(md) {
                    width: rem(220);
                    padding-top: rem(56);
                }

                @include media-breakpoint-up(lg) {
                    width: rem(308);
                    padding-top: rem(56);
                }
            }
        }
    }

    .experience-commerce_assets-colorBlockwithImageAndCTA:nth-child(4) {
        .image-block {
            order: 2;
        }

        .color-block {
            padding-left: rem(16);
            padding-right: rem(16);
        }

        .text-cta-container {
            padding: 0;
            width: 100%;

            @include media-breakpoint-up(md) {
                padding-right: rem(80);
                padding-left: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            @include media-breakpoint-up(lg) {
                max-width: 80%;
            }

            .text-block {
                text-align: left;

                @include media-breakpoint-up(md) {
                    text-align: right;
                    padding-right: 0;
                }
            }

            .pd-body-text {
                max-width: rem(300);
                padding-bottom: rem(24);

                @include media-breakpoint-up(md) {
                    padding-bottom: rem(28);
                    max-width: rem(380);
                }
            }

            .pd-optional-body-text {
                padding-top: rem(24);
                border-top: rem(2) solid $orange-border;
                text-transform: none;

                @include media-breakpoint-up(md) {
                    padding-top: rem(28);
                }
            }

            .cta-block {
                width: rem(220);
                margin-top: 0;
                padding-top: rem(24);
                padding-bottom: rem(24);

                @include media-breakpoint-up(md) {
                    width: rem(220);
                    padding-top: rem(56);
                }

                @include media-breakpoint-up(lg) {
                    width: rem(308);
                    padding-top: rem(56);
                }
            }
        }
    }

    .experience-commerce_assets-wideImageWithText:nth-child(1) {
        .wide-image-container {
            .main-content-container {
                .heading-text {
                    .heading {
                        margin-top: rem(58);
                        @include media-breakpoint-up(lg) {
                            margin-bottom: 0;
                        }

                        .heading-line-1 {
                            margin-bottom: 0;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }

    .experience-commerce_assets-wideImageWithText:nth-child(5) {
        .rich-text-content {
            margin-top: rem(24);

            @include media-breakpoint-up(md) {
                margin-top: rem(30);
            }
        }
    }
}

// active Page
.active {

    .experience-commerce_assets-imageWithTextAndTwoCTA {
        .image-with-text-container {
            padding: 0 2%;
        }
    }

    .experience-commerce_assets-bannerImageWithText {
        .component-banner {
            padding: 0 2%;
        }
    }

    .experience-commerce_assets-fullWidthCarouselComponent,
    .experience-commerce_assets-imageWithTextAndTwoCTA {
        .cta-text {
            padding-top: rem(8);

            a {

                &:hover,
                &:focus-visible,
                &:focus,
                &:active,
                &:visited {
                    outline: none;
                }
            }

            .first-cta-text {
                border: none;
            }
        }
    }

    .experience-commerce_assets-fullWidthCarouselComponent {
        .heading-text-image {
            height: auto;

            @include media-breakpoint-up(md) {
                height: rem(140);
            }

            @include media-breakpoint-up(lg) {
                height: auto;
            }
        }
    }

    .experience-commerce_assets-fullWidthCarouselComponent:nth-child(1) {
        .text-container-placement {
            max-width: none;

            @include media-breakpoint-up(md) {
                max-width: 44%;

            }
        }
    }

    .experience-commerce_assets-imageWithTextAndTwoCTA {
        .heading-text {
            .heading {
                padding-top: rem(20);

                @include media-breakpoint-up(md) {
                    padding-top: rem(80);
                }
            }

            .sub-heading {
                margin-bottom: 0;
            }

            .heading,
            .sub-heading {
                display: flex;
                position: absolute;
                top: 0;
                margin-top: 0;
                height: calc(100% - rem(185));

                @include media-breakpoint-up(md) {
                    display: initial;
                    position: relative;
                    height: initial;
                }
            }

            .description {
                padding: 0 rem(20);

                @include media-breakpoint-up(md) {
                    padding: 0 rem(8);
                }
            }
        }

        .image-component {
            .text-container-placement {
                .cta-text {
                    padding-top: rem(24);
                    margin-bottom: 0;
                }
            }
        }
    }

    .experience-commerce_assets-imageWithTextAndTwoCTA:nth-child(5) {
        .image-with-text-container {
            .image-component {
                .text-container-placement {
                    padding-left: rem(30);
                    align-items: center;
                    @include media-breakpoint-up(md){
                        align-items: flex-start;
                        padding-left: 0;
                    }
                    @include media-breakpoint-up(lg) {
                        margin-left: rem(60);
                    }
                }
    
                .heading-text {
                    .sub-heading {
                        align-items: center;
                        top: 20%;
    
                        @include media-breakpoint-up(md) {
                            margin-bottom: 0;
                            display: flex;
                            top: initial;
                            padding-left: 0;
                            justify-content: left;
                        }
                    }
    
                    .heading-text-image {
                        height: auto;
                    }
    
                    .description {
                        text-align: center;
    
                        @include media-breakpoint-up(md) {
                            margin-top: 0;
                            padding-top: rem(24);
                            text-align: left;
                            padding-left: 0;
                            max-width: 45%;
                        }
                    }
                }
            }
        }

        .cta-text {
            .first-cta-text {
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                    padding-left: 0;
                }
            }
        }
    }

    .experience-commerce_assets-imageWithTextAndTwoCTA:nth-child(9) {
        .image-with-text-container{
            .image-component{
                .text-container-placement{
                    align-items: center;
                    @include media-breakpoint-up(md) {
                        align-items: flex-start;
                    }
                    .heading-text {
                        .heading {
                            align-items: center;
                            justify-content: flex-end;
                            padding-left: rem(130);

                            @include media-breakpoint-up(md) {
                                text-align: left;
                                justify-content: initial;
                            }
                        }

                        .description {
                            padding-left: rem(20);

                            @include media-breakpoint-up(md) {
                                text-align: left;
                                margin-top: 0;
                                padding-top: rem(24);
                                padding-left: rem(130);
                            }
                        }
                    }
                }
            }
        }

        .cta-text {
            .first-cta-text {
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                    padding-left: rem(130);
                }
            }
        }
    }

    .experience-commerce_assets-bannerImageWithText {
        .component-banner {
            .component-img-text {
                .banner-text {
                    padding: 0;
                    order: 2;

                    @include media-breakpoint-up(md) {
                        padding-left: rem(40);
                        padding-right: rem(40);
                        order: 0;
                    }

                    @include media-breakpoint-up(lg) {
                        padding-left: rem(120);
                        padding-right: rem(120);
                    }

                    .banner-heading {
                        padding-right: 0;

                        @include media-breakpoint-up(md) {
                            padding-right: rem(28);
                        }
                    }

                    .banner-subheading {
                        padding-right: rem(60);
                        padding-left: rem(60);

                        @include media-breakpoint-up(md) {
                            padding-top: rem(24);
                            padding-right: rem(16);
                            padding-left: 0;
                        }
                    }

                    .shop-link {
                        padding-top: rem(24);
                    }
                }
            }
        }
    }

    .experience-commerce_assets-bannerImageWithText:nth-child(7) {
        .component-banner {
            .banner-text {
                order: 2;

                @include media-breakpoint-up(md) {
                    order: 3;
                }
            }

            .product-left-image {
                order: 1;

                @include media-breakpoint-up(md) {
                    order: 1;
                }
            }

            .product-right-image {
                order: 3;

                @include media-breakpoint-up(md) {
                    order: 2;
                }
            }
        }
    }
}
